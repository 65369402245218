import React from 'react';
import AllRoute from '../router'
import './App.css';
import '../../css/Responsive.css'


const App = () => { 

  return (
    <div className="App br-app" id='scrool'>
          <AllRoute/>
    </div>
  );
}

export default App;
